import React, { useState, useEffect } from 'react';
import './Todaywork.css';
import { FaStar } from "react-icons/fa";
import { database, auth } from '../firebase'; 
import { ref, get, child, update } from 'firebase/database'; 

const Todaywork = () => {
  const [products, setProducts] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(null); 
  const [completedTasks, setCompletedTasks] = useState([]); // Store completed tasks
  const [rewardPrice, setRewardPrice] = useState(null);

  // Fetch current user ID on mount
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserId(user.uid); 
        fetchCompletedTasks(user.uid); // Fetch completed tasks for the current user
      }
    });

    return () => unsubscribe(); 
  }, []);

  // Fetch completed tasks for the current user
  const fetchCompletedTasks = async (userId) => {
    try {
      const dbRef = ref(database, `completedTasks/${userId}`);
      const snapshot = await get(dbRef);

      if (snapshot.exists()) {
        const completedData = snapshot.val();
        setCompletedTasks(Object.keys(completedData)); // Store product IDs of completed tasks
      } else {
        setCompletedTasks([]); // No completed tasks
      }
    } catch (error) {
      console.error('Error fetching completed tasks:', error);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const dbRef = ref(database);
        const snapshot = await get(child(dbRef, 'dailyTasks'));

        if (snapshot.exists()) {
          const productData = snapshot.val();
          const productsArray = Object.keys(productData).map(key => ({
            id: key,
            ...productData[key]
          }));
          setProducts(productsArray);
        } else {
          console.log('No product data found');
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleStarClick = (productId, index, price) => {
    setRewardPrice(Number(price));
    setProducts(products.map(product =>
      product.id === productId ? { ...product, rating: index } : product
    ));
  };

  const handleSubmit = async (productId) => {
    if (!currentUserId) {
      console.error('User not authenticated');
      return;
    }
    console.log('rewardPrice:', rewardPrice); // Debugging information

    if (rewardPrice === null || isNaN(rewardPrice) || rewardPrice <= 0) {
      console.error('Invalid rewardPrice:', rewardPrice);
      return;
    }

    const userRef = ref(database, `users/${currentUserId}`);
    const taskRef = ref(database, `completedTasks/${currentUserId}/${productId}`);
    try {
      const userSnapshot = await get(userRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        const currentCoins = Number(userData.coins) || 0;

        const newCoins = currentCoins + rewardPrice;
        await update(userRef, {
          coins: newCoins
        });
      }
      await update(taskRef, {
        status: 'done', 
        productId,
        userId: currentUserId,
        completedAt: new Date().toISOString(),
      });
      console.log('Task saved successfully');
      fetchCompletedTasks(currentUserId); 
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };


  const filteredProducts = products.filter(product => !completedTasks.includes(product.id));

  return (<>
    <center><b className='Heading'>Today Task</b></center>
    <div className='todaywork-grid'>
    
      {filteredProducts.map((product) => (
        <div key={product.id} className='todaywork-item'>
          <img src={product.imageUrl} className='product-img' alt={product.name} />
          <p><b>{product.name}</b></p>
          <p>Price:<b> RS {product.price}</b></p>
          <div className='rating-area'>
            {[...Array(5)].map((star, index) => {
              const starIndex = index + 1;
              return (
                <FaStar
                  key={starIndex}
                  className='star-icon'
                  onClick={() => handleStarClick(product.id, starIndex, product.price)}
                  color={starIndex <= (product.rating || 0) ? 'yellow' : 'grey'} 
                  style={{ cursor: 'pointer' }}
                />
              );
            })}
          </div>
          <button className='submit-btn' onClick={() => handleSubmit(product.id)}>
            Submit
          </button>
        </div>
      ))}
    </div>
    </>
  );
};

export default Todaywork;
