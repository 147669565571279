import React, { useState, useEffect } from 'react';
import { ref, onValue, get, query, orderByChild, equalTo } from 'firebase/database';
import { database, auth } from '../firebase'; 

const Team = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [data, setData] = useState(null);
  const [referralUsers, setReferralUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [plans, setPlans] = useState({}); // Updated to handle multiple plans

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = auth.currentUser;
      if (user) {
        setCurrentUser(user.uid);
      } else {
        setLoading(false);
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) return; // If no user, return early

      try {
        const dbRef = ref(database, `users/${currentUser}`);
        const snapshot = await get(dbRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          setData(userData); // Store the data in state
        } else {
          setData(null); // Handle case where no data is found
        }
      } catch (error) {
        setError(error); // Handle errors
      } finally {
        setLoading(false); // Stop loading indicator
      }
    };

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    const fetchReferralUsers = async () => {
      if (!data || !data.username) return; // If no data or username, return early

      try {
        const usersRef = ref(database, 'users');
        const referralQuery = query(
          usersRef,
          orderByChild('referralCode'),
          equalTo(data.username)
        );

        const referralSnapshot = await get(referralQuery);
        if (referralSnapshot.exists()) {
          const referralData = referralSnapshot.val();
          const usersArray = Object.values(referralData);
          setReferralUsers(usersArray); 
        } else {
          setReferralUsers([]);
        }
      } catch (error) {
        setError(error); // Handle errors
      }
    };

    fetchReferralUsers();
  }, [data]);



  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
  
    return `${day}/${month}/${year}`;
  };


  return (
    <div>
      <h1>Team</h1>
      {currentUser ? (
        <div>
          <p>Number Of Referals: <b>{referralUsers.length}</b></p>
         
          <ul>
          <div className='referal-item'>
        <p><b>ID</b></p>
        <p><b>Name</b></p>
         

          
            <p><b>Earned Coins</b></p>
            <p><b>Username</b></p>
            <p><b>Rewards</b></p>
            <p><b>Register Date</b></p>
        </div>
            {referralUsers.length > 0 ? (
              referralUsers.map((user, index) => (
                <div className="referal-item" key={user.id}>
                  <p>{index + 1}</p>
                  <p>{user.name}</p>
                  <p>{user.coins}</p>
                  <p>{user.username}</p>
                  <p>100</p>
                  <p>{formatDate(user.creationDate)}</p>
                </div>
              ))
            ) : (
              <li>No referral users found</li>
            )}
          </ul>
        </div>
      ) : (
        <p>No user logged in</p>
      )}
    </div>
  );
};

export default Team;
