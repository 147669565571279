import React, { useEffect, useState } from 'react';
import { ref, onValue } from "firebase/database";
import { database } from '../firebase';  // Your Firebase setup file
import './Users.css';

const Users = () => {
  const [userlist, setUserlist] = useState([]);
  const [plans, setPlans] = useState({});

  useEffect(() => {
    // Fetch users from Firebase
    const userRef = ref(database, 'users');

    const unsubscribe = onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      const usersArray = userData
        ? Object.entries(userData).map(([key, value]) => ({
            id: key,
            ...value
          }))
        : [];
      setUserlist(usersArray);
    });

    return () => unsubscribe(); // Cleanup the subscription
  }, []);

  useEffect(() => {
    // Fetch plan data for each user
    userlist.forEach((user) => {
      if (user.selectedPlanId) {
        const planRef = ref(database, `plans/${user.selectedPlanId}`);

        const unsubscribe = onValue(planRef, (snapshot) => {
          const planData = snapshot.val();
          if (planData) {
            setPlans((prevPlans) => ({
              ...prevPlans,
              [user.id]: planData.name,
            }));
          }
        });

        return () => unsubscribe(); // Cleanup the subscription
      }
    });
  }, [userlist]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
  
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="user-list">
        <h2 className='heading'>Users List</h2>
        <div className='register-user-item'>
        <p><b>ID</b></p>
        <p><b>Name</b></p>
            <p><b>Email</b></p>
            <p><b>Phone No</b></p>

            <p><b>Username</b></p>
            <p><b>Plan</b></p>
            <p><b>Payment</b></p>
            <p><b>Register Date</b></p>
        </div>
      {userlist.length > 0 ? (
        userlist.map((user,index) => (
          <div key={user.id} className="register-user-item">
              <p>{index+1}</p>
            <p>{user.name}</p>
            <p>{user.email}</p>
            <p>{user.phone}</p>
            <p>{user.username}</p>
            <p>{plans[user.id] ? plans[user.id] : "No plan selected"}</p>
            <p>
              
              <span
                style={{
                  color: user.payment ? 'green' : 'red',
                  fontWeight: 'bold'
                }}
              >
                {user.payment ? ' True' : ' False'}
              </span>
            </p>
            <p>{formatDate(user.creationDate)}</p>
          </div>
        ))
      ) : (
        <p>No users available.</p>
      )}
    </div>
  );
};

export default Users;
