import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Dashboard.css'; // Import the CSS file
import { IoLogOut } from "react-icons/io5";
import Logo from '../Admin/Logo';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase'; 
const Dashboard = () => {
  const navigate = useNavigate(); 

  const items = [
    { id: 1, title: 'Profile' },
    { id: 2, title: 'Today Work' },
    { id: 3, title: 'Your Wallet' },
    { id: 4, title: 'My Team' },
    { id: 5, title: 'Invite Team' },
    { id: 6, title: 'Daily Reward' },
    { id: 7, title: 'Contact us' },
  ];

  const data = [
    { id: 1, name: 'Ali Khan', amount: 1000 },
    { id: 2, name: 'Sara Ahmed', amount: 1500 },
    { id: 3, name: 'Usman Tariq', amount: 1200 },
    { id: 4, name: 'Ayesha Malik', amount: 2000 },
    { id: 5, name: 'Ahmed Raza', amount: 1750 },
    { id: 6, name: 'Fatima Syed', amount: 2200 },
    { id: 7, name: 'Bilal Ansari', amount: 1300 },
    { id: 8, name: 'Zainab Qureshi', amount: 2400 },
    { id: 9, name: 'Hassan Abbas', amount: 1100 },
    { id: 10, name: 'Mehwish Jameel', amount: 1800 },
    { id: 11, name: 'Fahad Shah', amount: 1900 },
    { id: 12, name: 'Madiha Farooq', amount: 2500 },
    { id: 13, name: 'Zeeshan Baig', amount: 1350 },
    { id: 14, name: 'Sana Ali', amount: 1450 },
    { id: 15, name: 'Kamran Nawaz', amount: 1600 },
    { id: 16, name: 'Iqra Khalid', amount: 2100 },
    { id: 17, name: 'Junaid Iqbal', amount: 2700 },
    { id: 18, name: 'Rabia Aslam', amount: 1900 },
    { id: 19, name: 'Tariq Mehmood', amount: 2800 },
    { id: 20, name: 'Shazia Anwar', amount: 1950 },
  ];

  const handleItemClick = (title) => {
    switch (title) {
      case 'Profile':
        navigate('/profile'); // Navigate to Profile page
        break;
      case 'Today Work':
        navigate('/todaywork'); // Add navigation for other items as needed
        break;
      case 'Your Wallet':
        navigate('/wallet');
        break;
      case 'My Team':
       navigate('/team')
        break;
      case 'Invite Team':
        navigate('/invite');
        break;
      case 'Daily Reward':
        navigate('/dailybonus');
        break;
      case 'Contact us':
        // Add navigation path
        break;
      default:
        break;
    }
  };
  
  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User logged out');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  return (
    <div className='Dashboard'>
      <IoLogOut className='logout' onClick={handleLogout}/>
      <Logo/>
      <p className='sub'>Top 100 User Play List</p>
      <div className='Verticle-news'>
        <center>
          <marquee direction="up">
            {data.map((item, index) => (
              <h3 key={index}>
                <span>Name: {item.name}</span> <br />
                <span>Amount: {item.amount}</span>
              </h3>
            ))}
          </marquee>
        </center>
      </div>
      <div className='Horizontal-news'>
        <marquee>
          <h2>
            Offer 1: if any one make 10 reffer. Offer 2: if any one make 10 reffer. Offer 5: if any one make 10 reffer.
          </h2>
        </marquee>
      </div>
      <div className="grid-container">
        {items.map(item => (
          <div 
            key={item.id} 
            className="grid-item" 
            onClick={() => handleItemClick(item.title)} 
          >
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
