import React, { useState, useEffect } from 'react';
import { database, auth } from '../firebase'; 
import { ref, get } from 'firebase/database'; 
import './Wallet.css';

const Wallet = () => {
  const [currentBalance, setCurrentBalance] = useState(0);
  const [eBalance, setEBalance] = useState(0);
  const [pendingWithdraw, setPendingWithdraw] = useState(0);
  const [approvedWithdraw, setApprovedWithdraw] = useState(0);
  const [userId, setUserId] = useState(null);
const showAlert=()=>{
alert("Withdraw on Rank 10 only!!")
}
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
        try {
          const userRef = ref(database, `users/${user.uid}`);
          const snapshot = await get(userRef);

          if (snapshot.exists()) {
            const userData = snapshot.val();
            setCurrentBalance(userData.coins || 0);
            setEBalance(userData.ecoins || 0);
            // Assuming you have fields for pending and approved withdrawals
            setPendingWithdraw(userData.pendingWithdraw || 0);
            setApprovedWithdraw(userData.approvedWithdraw || 0);
          } else {
            console.log('No user data found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, []);
  return (
    <div className='wallet'>
      <div className="wallet-item">
        <span className="wallet-label">Current Balance:</span>
        <span className="wallet-value">{currentBalance}rs</span>
      </div>
      <div className="wallet-button">
        <button className='wallet-buttons' onClick={showAlert}>Withdraw current balance</button>
        <button className='wallet-buttons'onClick={showAlert}>Withdraw in Best wallet: {eBalance}rs</button>
      </div>
      <div className="wallet-item">
        <span className="wallet-label">Pending Withdraw:</span>
        <span className="wallet-value">{pendingWithdraw}rs</span>
      </div>
      <div className="wallet-item">
        <span className="wallet-label">Approved Withdraw:</span>
        <span className="wallet-value">{approvedWithdraw}rs</span>
      </div>
      <h2 className='history-heading'>History</h2>
      <div className='table'>
        <h4 className='th'>Withdraw Amount</h4>
        <h4 className='th'>Withdraw Status</h4>
        <h4 className='th'>Date & Time</h4>
      </div>
    </div>
  );
};

export default Wallet;
