import React, { useState, useEffect } from 'react';
import { database, auth } from '../firebase'; 
import { ref, get, child, update } from 'firebase/database'; 
import './DailyReward.css'; // Ensure you have CSS for styling

const DailyReward = () => {
  const [rewards, setRewards] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(null); 
  const [completedBonuses, setCompletedBonuses] = useState([]);
  const [timer, setTimer] = useState(null);
  const [claimableRewardId, setClaimableRewardId] = useState(null);
  const [rewardPrice, setRewardPrice] = useState(null); // Initialize as null

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserId(user.uid);
        fetchCompletedBonuses(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchCompletedBonuses = async (userId) => {
    try {
      const dbRef = ref(database, `completedBonuses/${userId}`);
      const snapshot = await get(dbRef);

      if (snapshot.exists()) {
        const completedData = snapshot.val();
        setCompletedBonuses(Object.keys(completedData));
      } else {
        setCompletedBonuses([]);
      }
    } catch (error) {
      console.error('Error fetching completed bonuses:', error);
    }
  };

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const dbRef = ref(database);
        const snapshot = await get(child(dbRef, 'dailyBonuses'));

        if (snapshot.exists()) {
          const rewardData = snapshot.val();
          const rewardsArray = Object.keys(rewardData).map(key => ({
            id: key,
            ...rewardData[key]
          }));
          setRewards(rewardsArray);
        } else {
          console.log('No daily bonuses found');
        }
      } catch (error) {
        console.error('Error fetching daily bonuses:', error);
      }
    };

    fetchRewards();
  }, []);

  const handleVisitClick = (rewardId, rewardName, price) => {
    const url = rewardName.startsWith('http') ? rewardName : `http://${rewardName}`;
    setClaimableRewardId(rewardId);
    setRewardPrice(Number(price)); // Ensure price is a number
    setTimer(10);

    // Clear any existing timer
    if (timer !== null) {
      clearInterval(timer);
    }

    const countdown = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer <= 1) {
          clearInterval(countdown);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    window.open(url, '_blank');
  };

  const handleSubmit = async (rewardId) => {
    if (!currentUserId) {
      console.error('User not authenticated');
      return;
    }

    console.log('rewardPrice:', rewardPrice); // Debugging information

    if (rewardPrice === null || isNaN(rewardPrice) || rewardPrice <= 0) {
      console.error('Invalid rewardPrice:', rewardPrice);
      return;
    }

    const userRef = ref(database, `users/${currentUserId}`);
    const bonusRef = ref(database, `completedBonuses/${currentUserId}/${rewardId}`);

    try {
      const userSnapshot = await get(userRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        const currentCoins = Number(userData.ecoins) || 0;

        const newCoins = currentCoins + rewardPrice;
        await update(userRef, { ecoins: newCoins });

        await update(bonusRef, {
          status: 'done',
          rewardId,
          userId: currentUserId,
          completedAt: new Date().toISOString(),
        });

        console.log('Bonus saved successfully');
        fetchCompletedBonuses(currentUserId);
        setClaimableRewardId(null);
        setTimer(null);
        setRewardPrice(null); 
      } else {
        console.error('User data not found');
      }
    } catch (error) {
      console.error('Error saving bonus:', error);
    }
  };

  const filteredRewards = rewards.filter(reward => !completedBonuses.includes(reward.id));

  return (
    <div className='todaywork-grid'>
      {filteredRewards.map((reward) => (
          <div className="todaywork-item">
          <p>Price: <b>RS {reward.price}</b></p>
          {claimableRewardId === reward.id ? (
            timer > 0 ? (
              <button className='wallet-buttons' disabled>
                Please wait ({timer}s)
              </button>
            ) : (
              <button
                className='submit-btn'
                onClick={() => handleSubmit(reward.id)}
              >
                Claim Reward
              </button>
            )
          ) : (
            <a href={reward.name} target="_blank" rel="noopener noreferrer">
              <button
                className='wallet-buttons'
                onClick={() => handleVisitClick(reward.id, reward.name, reward.price)}
              >
                Visit
              </button>
            </a>
          )}
        </div>
      ))}
    </div>
  );
};

export default DailyReward;
